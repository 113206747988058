<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded.sync="expanded"
          :show-expand.sync="showExpand"
          :single-expand="singleExpand"
          :item-class-fn="itemClassFn"
          :hide-top-action-btn="false"
          :hide-top-action="['create']"
          @inputChange="handleChange"
          @currentItems="handleCurrentItems"
        >
          <template #top-action-buttons>
            <v-btn
              color="primary"
              class="mr-3"
              small
              @click="domainsRegister"
            >
              {{ $t('Create') }}
            </v-btn>
          </template>

          <template #[`item.completed_at`]="{ item }">
            {{ item.completed_at | dateFilter }}
            <slot
              name="dates"
              :item="item"
            />
          </template>

          <template #[`item.domains_num`]="{ item }">
            {{ item.domains_num }} / {{ item.total_domains }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              v-if="Object.keys(item.log || {}).length > 0"
              :colspan="headers.length"
            >
              <v-row
                v-if="Object.keys(item.log || {}).length > 0"
                class="job-timeline-container"
              >
                <v-col
                  v-for="(domain, key) in item.log"
                  :key="`log_${key}`"
                  xl="3"
                  lg="3"
                  md="4"
                  sm="4"
                  class="d-flex justify-center"
                >
                  <SDRLog
                    :data="item.log"
                    :domain="key"
                    :task-id="item.id"
                    @remove="removeDomainFromTask"
                  />
                </v-col>
              </v-row>
            </td>
            <td
              v-else
              :colspan="headers.length"
              class="v-data-table__empty-wrapper"
            >
              {{ $t('Loading... Please wait') }}
            </td>
          </template>
        </table-data>
        <domains-register
          v-if="this.$can('domains.providers.create') || this.$can('tasks.sdr.create')"
          :provider-selection="true"
          :affiliate-selection="true"
          :dialog="domainsRegisterDialog"
          :edited-item="domainsRegisterEditedItem"
          @close="domainsRegisterDialogClose"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import DomainsRegister from '../domains/dialogs/DomainsRegister'
import SDRLog from './components/SDRLog'

export default {
  name: 'SingleDomainRegistration',
  filters: {
    dateFilter (date = null) {
      try {
        if (!date || date === undefined) {
          return ''
        }
        if (date.indexOf('T')) {
          const dateArray = date.split('T')
          return dateArray.length === 2 ? `${dateArray[0]} ${dateArray[1].substring(0, 8)}` : ''
        }
      } catch (e) {
        return ''
      }
    }
  },
  components: {
    SDRLog,
    DomainsRegister
  },
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('tasks.sdr.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'tasks.sdr',
    expanded: [],
    showExpand: true,
    singleExpand: true,
    editedItem: {},
    // DOMAINS REGISTER
    domainsRegisterDialog: false,
    domainsRegisterEditedItem: {}
  }),
  computed: {
    getEditedItem () {
      return this.$store.getters[`__${this.resource}/getEditedItem`] || []
    },
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    },
    getCurrItems() {
      return this.$store.getters[`__${this.resource}/items`] || []
    }
  },
  watch: {
    getCurrItems: {
      handler (v, ov) {
        if (v.length > 0 && this.$route.params.id) {
          this.expanded = [v.find(item => item.id === this.$route.params.id)]
        }
      },
      deep: true
    }
  },
  async mounted () {
    this.title = this.$t('tasks.sdr.title')
    this.tableHeaders = [
      {
        text: this.$t('tasks.sdr.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('tasks.sdr.table.statusText'),
        align: 'start',
        value: 'status_text',
        sortable: false
      },
      {
        text: this.$t('tasks.sdr.table.domainsNum'),
        align: 'center',
        value: 'domains_num',
        width: '100px'
      },
      {
        text: this.$t('tasks.sdr.table.completedAt'),
        align: 'center',
        value: 'completed_at',
        width: '100px'
      }
    ]
  },
  methods: {
    domainsRegister (item) {
      this.domainsRegisterDialog = true
      this.domainsRegisterEditedItem = {}
    },
    domainsRegisterDialogClose() {
      this.domainsRegisterDialog = false
      this.domainsRegisterEditedItem = {}
    },
    itemClassFn (item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      if (item.with_error === true) {
        classes += ' red lighten-5 '
      }
      return classes
    },
    handleChange (changes) {
      this.editedItem = changes
    },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    },
    removeDomainFromTask(domain) {}
  }
}
</script>

<style lang="css">
.job-timeline-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.job-timeline-container small.desc {
  padding-top: 7px;
  color: #606060 !important;
  line-height: 12px !important;
}
.job-timeline-container small.time {
  padding-top: 15px;
  color: #9f9f9f !important;
}
.job-timeline-container .v-card__title {
  background-color: #f4f4f4;
  font-size: 16px;
  padding: 5px 15px;
  box-shadow: none;
}
.job-timeline-container .v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 60px) !important;
}
.job-timeline-container .v-timeline-item__divider {
  min-width: 60px !important;
}
.job-timeline-container .v-timeline-item,
.job-timeline-container .v-timeline {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
}
.job-timeline-container  .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(30px - 1px) !important;
}
.job-timeline-container .theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none !important;
}
.job-timeline-container .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: none !important;
}
.job-timeline-container .v-timeline-item__dot {
  z-index: 1;
}
</style>
