var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"expanded":_vm.expanded,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"item-class-fn":_vm.itemClassFn,"hide-top-action-btn":false,"hide-top-action":['create']},on:{"update:expanded":function($event){_vm.expanded=$event},"update:showExpand":function($event){_vm.showExpand=$event},"update:show-expand":function($event){_vm.showExpand=$event},"inputChange":_vm.handleChange,"currentItems":_vm.handleCurrentItems},scopedSlots:_vm._u([{key:"top-action-buttons",fn:function(){return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","small":""},on:{"click":_vm.domainsRegister}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])]},proxy:true},{key:"item.completed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.completed_at))+" "),_vm._t("dates",null,{"item":item})]}},{key:"item.domains_num",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.domains_num)+" / "+_vm._s(item.total_domains)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(Object.keys(item.log || {}).length > 0)?_c('td',{attrs:{"colspan":headers.length}},[(Object.keys(item.log || {}).length > 0)?_c('v-row',{staticClass:"job-timeline-container"},_vm._l((item.log),function(domain,key){return _c('v-col',{key:("log_" + key),staticClass:"d-flex justify-center",attrs:{"xl":"3","lg":"3","md":"4","sm":"4"}},[_c('SDRLog',{attrs:{"data":item.log,"domain":key,"task-id":item.id},on:{"remove":_vm.removeDomainFromTask}})],1)}),1):_vm._e()],1):_c('td',{staticClass:"v-data-table__empty-wrapper",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(_vm.$t('Loading... Please wait'))+" ")])]}}],null,true)}),(this.$can('domains.providers.create') || this.$can('tasks.sdr.create'))?_c('domains-register',{attrs:{"provider-selection":true,"affiliate-selection":true,"dialog":_vm.domainsRegisterDialog,"edited-item":_vm.domainsRegisterEditedItem},on:{"close":_vm.domainsRegisterDialogClose}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }