<template>
  <v-card
    elevation="1"
    width="100%"
  >
    <v-card-title
      :class="log.running === true ? ` blue-grey lighten-5 loading-background ` : ' grey lighten-4 '"
    >
      <v-text-field
        ref="editDomainNameInput"
        v-model="editDomainName"
        :loading="editDomainLoading"
        hide-details
        flat
        solo
        readonly
        @keyup.enter="$event.target.blur()"
        @dblclick="log.running !== true ? ($event.target.readOnly = false) : () => {}"
        @blur="update"
      />
      <v-spacer />

      <v-btn-toggle
        v-can="'tasks.sdr.controlJob'"
        multiple
        small
      >
        <tooltip
          v-if="log.running === true"
          :text="$t('Stop this task')"
        >
          <template>
            <v-btn
              small
              icon
            >
              <v-icon
                small
                @click="stop"
              >
                mdi-stop
              </v-icon>
            </v-btn>
          </template>
        </tooltip>

        <tooltip
          v-else-if="log.stopped === true"
          :text="$t('Start this task')"
        >
          <template>
            <v-btn
              small
              icon
            >
              <v-icon
                small
                @click="start"
              >
                mdi-play-outline
              </v-icon>
            </v-btn>
          </template>
        </tooltip>
        <tooltip
          v-if="log.running === false"
          :text="$t('Remove this task')"
        >
          <template>
            <v-btn
              small
              icon
            >
              <v-icon
                small
                @click="remove"
              >
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
        </tooltip>
        <tooltip
          v-if="log.running === false && log.finished === true"
          :text="$t('Restart this task')"
        >
          <template>
            <v-btn
              small
              icon
            >
              <v-icon
                small
                @click="restart"
              >
                mdi-replay
              </v-icon>
            </v-btn>
          </template>
        </tooltip>
      </v-btn-toggle>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-timeline
        align-top
        dense
      >
        <div
          v-for="(item, key) in buildTimeline()"
          :key="key"
        >
          <v-timeline-item
            v-if="item.value !== null"
            small
            :color="typeof item.color === 'function' ? item.color(log) : (item.color ? item.color : (item.value === true ? 'primary' : 'orange'))"
          >
            <div>
              <div class="font-weight-normal">
                <strong>{{ $t(item.label) }}</strong> @{{ `${ item.date !== null ? $helper.timeFilter(item.date) : ( item.last !== undefined ? $helper.timeFilter(item.last) : '' )}` }}
              </div>
              <div class="font-weight-light">
                {{ $t(item.desc) }}
              </div>
            </div>
          </v-timeline-item>
        </div>
      </v-timeline>
      <v-alert
        v-if="log.lastError !== undefined && log.lastError !== ''"
        dense
        outlined
        type="error"
      >
        {{ log.lastError }}
      </v-alert>
      <v-alert
        v-if="log.lastMessage !== undefined && log.lastMessage !== ''"
        dense
        text
        type="success"
      >
        {{ log.lastMessage }}
      </v-alert>
      <count-down
        v-if="log.running"
        :end-date="new Date(new Date(log.lastUpdatedAt).getTime() + log.delay * 15000)"
      />
      <code
        v-if="log.running && log.registeredCheckedNum > 0"
        class="ml-2"
      >{{ $t('Retries') }}:  {{ log.registeredCheckedNum || 0 }}</code>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SDRLog',
  props: {
    taskId: {
      type: String,
      default: ''
    },
    domain: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    steps: {},
    showItems: true,
    timers: {},
    editDomainName: '',
    prevEditDomainName: '',
    editDomainLoading: false
  }),
  computed: {
    log () {
      return typeof this.data[this.domain] === 'object' ? this.data[this.domain] : {}
    }
  },
  mounted () {
    this.editDomainName = this.$helper.capFirstLetter(this.domain)
  },
  methods: {
    async stop(data) {
      if (!this.$can('tasks.sdr.controlJob')) {
        return
      }
      await this.$store.dispatch('tasksSdr/controlTask', {
        id: this.taskId,
        inputData: {
          domain: this.domain,
          action: 'stop'
        }
      })
    },
    async start(data) {
      if (!this.$can('tasks.sdr.controlJob')) {
        return
      }
      await this.$store.dispatch('tasksSdr/controlTask', {
        id: this.taskId,
        inputData: {
          domain: this.domain,
          action: 'start'
        }
      })
    },
    async restart(data) {
      if (!this.$can('tasks.sdr.controlJob')) {
        return
      }
      await this.$store.dispatch('tasksSdr/controlTask', {
        id: this.taskId,
        inputData: {
          domain: this.domain,
          action: 'restart'
        }
      })
    },
    async update(e) {
      if (!this.$can('tasks.sdr.controlJob')) {
        return
      }
      e.target.readOnly = true
      if (e.target.value === '') {
        e.target.value = this.domain
      }
      if (String(e.target.value).toLowerCase() !== String(this.domain).toLowerCase()) {
        this.editDomainLoading = true
        this.prevEditDomainName = this.domain
        this.editDomainName = e.target.value
        try {
          await this.$store.dispatch('tasksSdr/updateTask', {
            id: this.taskId,
            inputData: {
              domain: this.domain,
              newDomain: e.target.value,
              action: 'update'
            }
          })
          this.editDomainName = this.$helper.capFirstLetter(e.target.value)
        } catch (err) {
          this.editDomainName = this.$helper.capFirstLetter(this.domain)
          this.$toast.error(err.response.data.message)
        } finally {
          this.editDomainLoading = false
        }
      }
    },
    async remove() {
      if (!this.$can('tasks.sdr.controlJob')) {
        return
      }
      await this.$store.dispatch('tasksSdr/controlTask', {
        id: this.taskId,
        inputData: {
          domain: this.domain,
          action: 'remove'
        }
      })
    },
    buildTimeline() {
      const timeline = [
        {
          label: 'Created',
          desc: 'Domain registration task created successfully',
          value: true,
          date: this.log.createdAt
        },
        {
          label: 'Domain availability check',
          desc: 'Domain registration availability check',
          value: this.log.availableReg,
          date: this.log.availableRegCheckedAt,
          last: this.log.availableRegCheckedAt,
          color: (log) => {
            if (log.availableReg === false && log.registered !== true) {
              return 'red'
            } else {
              return 'primary'
            }
          }
        },
        {
          label: 'Request sent',
          desc: 'Sending a domain registration request',
          value: this.log.sendRegReq,
          date: this.log.sendRegReqAt
        },
        {
          label: 'Find domain on provider',
          desc: 'Checking the availability of a domain with a provider',
          value: this.log.registered,
          date: this.log.registeredAt,
          last: this.log.registeredLastCheckAt,
          num: this.log.registeredCheckedNum
        },
        {
          label: 'Finished',
          desc: 'Domain registration task is finished',
          value: this.log.finished,
          date: this.log.finishedAt
        },
        {
          label: 'Task is stopped',
          desc: 'The task has been stopped',
          value: this.log.stopped === false ? null : true,
          date: this.log.stoppedAt,
          color: 'red'
        }
      ]
      return timeline
    },
    dblclick(e) {
      e.target.readOnly = false
    }
  }
}
</script>
